/**
 * External dependencies.
 */
 import React from 'react';

 /**
  * LinkedinIcon Component.
  *
  * @return {jsx}
  */
 const LogoSmallCS = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="111"
      height="80"
      viewBox="0 0 111 80"
    >
      <defs>
        <path id="prefix__a" d="M.441.269h69.76v54.389H.441z"></path>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#000"
          d="M44.961 70.826c-6.49 6.491-19.898 3.607-29.946-6.441C4.968 54.337 2.084 40.93 8.574 34.439c6.491-6.49 19.899-3.607 29.946 6.44a40.522 40.522 0 011.847 1.977l-.072-6.801C40.241 30.94 36.51 26.6 31.458 25.8a27.33 27.33 0 00-4.3-.337C12.157 25.464 0 37.549 0 52.457S12.159 79.45 27.157 79.45c7.683 0 14.62-3.171 19.56-8.269l-.539-1.807c-.36.515-.766 1-1.217 1.452m57.106-55.376c-2.163-1.544-4.71-2.288-6.925-2.865l-.538-.138c-1.302-.336-2.65-.682-3.907-1.173-2.336-.91-3.672-2.472-3.968-4.645-.27-1.984.248-3.448 1.585-4.475C89.504 1.24 91 .936 92.89 1.223c2.305.35 4.408 1.454 6.43 3.374 1.51 1.436 2.632 2.874 3.528 4.528l.378.696.053-1.034c.008-.133.014-.227.013-.322l-.016-1.603c-.015-1.301-.03-2.647-.017-3.969.006-.63-.28-.89-.712-1.086A19.556 19.556 0 0096.912.2c-3.25-.419-5.873-.184-8.253.74-4.473 1.735-6.434 5.405-5.521 10.335.38 2.054 1.582 3.693 3.676 5.01 2.024 1.275 4.33 1.872 6.363 2.398 1.844.477 3.995 1.13 5.974 2.353 1.875 1.16 2.865 2.516 3.11 4.27.37 2.628-1.01 4.58-3.6 5.096-2.333.465-4.66.186-6.915-.828-3.36-1.512-6.003-3.967-8.08-7.506a22.823 22.823 0 01-.772-1.48c-.116-.235-.233-.473-.354-.71l-.208-.332-.203.356a.305.305 0 00-.065.18l.005.696c.02 2.557.038 5.113.072 7.67.003.23.187.487.363.602.409.265.95.607 1.518.895 2.042 1.036 4.337 1.693 7.015 2.008a27.26 27.26 0 003.179.205c1.537 0 2.887-.172 4.172-.52 2.63-.713 4.496-2.014 5.706-3.977.771-1.253 1.176-2.685 1.31-4.644.23-3.327-.862-5.801-3.337-7.567"
        ></path>
        <g transform="translate(39.752 25.04)">
          <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a"></use>
          </mask>
          <path
            fill="#000"
            d="M54.987 18.169c-4.312-.007-9.435-1.397-12.822-1.397-3.472 0-7.269.89-9.45 3.878 4.946-2.235 16.237-1.101 16.237 12.925 0 3.312-.728 6.624-3.069 8.966C27.59 27.163 16.326 13.316 16.326 7.455c0-3.635 3.127-5.853 6.359-5.853 4.31 0 8.2 5.304 8.498 9.39 2.433-1.335 3.937-6.096 2.905-8.518C31.584.938 27.863.447 25.04.293 17.662-.11 10.134 4.556 10.134 12.31c0 2.94 1.256 5.434 3.648 9.037 4.762 7.172 16.186 19.499 24.992 26.85-3.152 1.857-6.867 2.827-10.664 2.827-15.44 0-24.93-14.512-16.998-28.326C6.388 24.659.441 31.385.441 37.68c0 12.14 12.564 16.979 23.791 16.979 6.06 0 11.876-1.373 17.127-4.524 3.07 2.02 6.947 4.524 10.34 4.524 7.716 0 10.605-6.914 10.824-8.077-1.298 1.331-3.313 2.019-5.25 2.019-2.83 0-5.414-1.374-8.967-4.2 4.3-3.937 6.497-7.919 6.497-13.089 0-4.2-1.599-7.27-4.393-9.64 2.868 1.265 8.727 2.054 12.555 1.969 4.198-.094 6.811-2.13 7.142-3.961.481-2.668-1.02-6.223-1.637-7.998-.933 5.93-8.886 6.734-13.483 6.487"
            mask="url(#prefix__b)"
          ></path>
        </g>
      </g>
    </svg>
 );
 
 export default LogoSmallCS;
 