/**
 * External dependencies.
 */
 import React from 'react';
 import { useEffect, useState } from 'react';
 import  Link from 'gatsby-link';
 import { each, isEmpty } from 'lodash';
 import classnames from 'classnames';
 
 import DropdownIcon from '../icons/dropdown-icon';
// Uplers import { normalizePath } from "../../utils/functions";
 import CartIcon from "../cart/cart-icon";
//Uplers  import WishListIcon from "../wishlist/wishlist-icon";
 
 const Nav = ( props ) => {
 
	 const { headerMenuItems, concern } = props;
//  console.info(headerMenuItems);
	 const [ menuVisible, setMenuVisibility ] = useState( false );
	 const [ menuState, setMenuState ] = useState( {} );
 
	 // Eslint disable as headerMenuItems is available from the props so does not have a missing dependency.
	 /* eslint-disable */
	 useEffect( () => {
 
		 if ( Object.keys( headerMenuItems.edges ).length ) {
			 const newMenuState = {};
 
			 each( headerMenuItems.edges, ( item ) => {
				 newMenuState[ item.node.databaseId ] = { isOpen: false };
			 } );
 
			 setMenuState( newMenuState );
		 }
 
	 }, [] );
	 /* eslint-enable */
 
	 const handleSubMenuOpen = ( event, databaseId ) => {
		 event.stopPropagation();
 
		 setMenuState( {
			 ...menuState,
			 [ databaseId ]: { isOpen: ! menuState[ databaseId ].isOpen },
		 } );
	 };
 
	 const menuButtonClasses = classnames(
		 'header-nav-menu-btn hamburger header-nav__menu-btn hamburger--slider',
		 {
			 'is-active': menuVisible
		 }
	 );
 
	 const toggleMenu = () => {
		 setMenuVisibility( ! menuVisible );
		 document.body.classList.toggle( 'mobile-menu-open' );
	 };
 
	 return (
		 <>
			 <nav
				 className={ `header-nav ${
					 menuVisible ? 'menu-visible' : ''
				 }` }
			 >
				 <div className="header-nav__wrap">
					 { ! isEmpty( headerMenuItems.edges ) && ! isEmpty( menuState ) ? (
						 <ul className="header-nav__wrap">
							 { headerMenuItems.edges.map( ( menu ) => {
								const isParent = null == menu.node.parentId ? true : false;
								if( !isParent )
									return(null);
								 const hasChildren = null !== menu.node.childItems.nodes ? menu.node.childItems.nodes.length: false;
								 const isConcern = menu.node.label === "Concerns" ? true : false;
								 const parentMenuLink = (
									 <Link
										 className="header-nav__menu-link"
										 to={ menu.node.url } /* Uplers normalizePath( menu.node.url ) */
									 >
										 { menu.node.label }
									 </Link>
								 );
 
								 return (
									 <li
										 key={ menu.node.databaseId }
										 className={ `header-nav__menu-item ${
											 hasChildren
												 ? 'menu-has-children'
												 : ''
										 } ${
											 menuState[ menu.node.databaseId ].isOpen
												 ? 'child-menu-open'
												 : ''
										 }` }
									 >
										 { hasChildren ? (
											 <div className="header-nav__menu-link-container-with-arrow d-flex align-items-center justify-content-between cs-nav-dropdown">
												 { parentMenuLink }
												 <button
													 className="header-nav__toggle-menu-btn icon-button"
													 onClick={ ( event ) => handleSubMenuOpen( event, menu.node.databaseId ) }
													 onKeyDown={ ( event ) => handleSubMenuOpen( event, menu.node.databaseId ) }
												 >
												 <DropdownIcon />
												 </button>
											 </div>
										 ) : isConcern ? (
											<div className="header-nav__menu-link-container-with-arrow d-flex align-items-center justify-content-between cs-nav-dropdown">
											{ parentMenuLink }
											<button
												className="header-nav__toggle-menu-btn icon-button"
												onClick={ ( event ) => handleSubMenuOpen( event, menu.node.databaseId ) }
												onKeyDown={ ( event ) => handleSubMenuOpen( event, menu.node.databaseId ) }
											>
											<DropdownIcon />
											</button>
											</div>
										 ) : parentMenuLink }
										 
 
										 { /* Child Menu */ }
										 { hasChildren ? (
											 <div
												 className={ `header-nav__submenu ${
													 menuState[ menu.node.databaseId ].isOpen
														 ? 'child-menu-open'
														 : ''
												 }` }
											 >
											<div class="sub-content d-flex flex-wrap">
                           					 <div class="col-12 col-md-6 divider">
                              				 <div class="d-flex flex-column flex-md-row flex-wrap justify-content-between">
                                   			 <div class="flex-fill cs-product-categories">
                                        	<div class="list-head">
                                            Products
                                        	</div>
											<div class="list-group">
												 { menu.node.childItems.nodes.map(
													 ( subMenu ) => (
														 
															 <Link
																 className="header-nav__submenu-link"
																 to={ subMenu.url } /* Uplers normalizePath( menu.node.url ) */
															 >
																 { subMenu.label }
															 </Link>
														
													 )
												 ) }
											 </div></div></div></div>
											 
                            <div class="col-12 col-md-6 divider">
                                <div class="d-flex justify-content-end">
                                    <div class="flex-fill cs-bundle">
                                        <div class="img">
                                            <img width="500" height="423" src="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/images/home/Routines_Menu1.png" data-src="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/images/home/Routines_Menu1.png" class="cs-img-fluid mx-auto d-block" data-done="Loaded" />
                                        </div>
                                        <div class="d-flex justify-content-center cs-more-link">
                                            <a href="https://www.carrotstick.com/bundles/" class="cs-link d-flex align-items-center">
                                                Discover our bundles
                                                <img src="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/icons/arrow-24-px.svg" data-src="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/icons/arrow-24-px.svg" class="cs-img" alt="" loading="lazy" data-done="Loaded" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div></div>
											 </div>
										 ) : isConcern ? (
											<div
												className={ `header-nav__submenu ${
													menuState[ menu.node.databaseId ].isOpen
														? 'child-menu-open'
														: ''
												}` }
											>
											<div class="sub-content d-flex flex-wrap">
                           					 <div class="col-12 col-md-6 divider">
                              				 <div class="d-flex flex-column flex-md-row flex-wrap justify-content-between">
                                   			 <div class="flex-fill cs-product-categories">
                                        	<div class="list-head">
                                            Concern
                                        	</div>
											<div class="list-group">
												{ concern.nodes.map(
													( subMenu ) => (
														
															<Link
																className="header-nav__submenu-link"
																to={ `/concerns/#list-${subMenu.slug}` } /* Uplers normalizePath( menu.node.url ) */
															>
																{ subMenu.name }
															</Link>
														
													)
												) }
												</div></div>
												</div>
												</div>
												
												<div class="col-12 col-md-6 divider">
                                <div class="d-flex justify-content-end">
                                    <div class="flex-fill cs-bundle">
                                        <div class="img">
                                            <img width="500" height="423" data-class="LazyLoad" src="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/images/home/Routines_Menu1.png" data-src="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/images/home/Routines_Menu1.png" class="cs-img-fluid mx-auto d-block"/>
                                        </div>
                                        <div class="d-flex justify-content-center cs-more-link">
                                            <a href="https://www.carrotstick.com/bundles/" class="cs-link d-flex align-items-center">
                                                Discover our bundles
                                                <img data-class="LazyLoad" src="https://www.carrotstick.com/wp-content/uploads/blank.png" data-src="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/icons/arrow-24-px.svg" class="cs-img" alt="" loading="lazy"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div></div>
											</div>
										 ) : null }
									 </li>
								 );
							 } ) }
							 {/*Uplers <li className="header-nav__menu-item">
								 <Link className="header-nav__menu-link" to="/checkout">Checkout</Link>
							 </li> */}
							 <li className="header-nav__menu-item">
								 <Link className="header-nav__menu-link" to="/products">Shop All Products</Link>
							 </li>
						 </ul>
					 ) : null }
 
				 </div>
			 </nav>
			 <div className="cart-icon-wrap">
				 {/*Uplers <WishListIcon/> */}
				 <CartIcon/>
				 {/*Burger menu*/}
				 <button
					 className={ menuButtonClasses }
					 type="button"
					 onClick={ toggleMenu }
					 onKeyDown={ toggleMenu }
				 >
				 <span className="hamburger-box">
					 <span className="hamburger-inner">
						 <span className="screen-reader-text">Toogle Menu</span>
					 </span>
				 </span>
				 </button>
			 </div>
		 </>
	 );
 };
 
 export default Nav;
 